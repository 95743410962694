import Axios from "axios"
import qs from "qs"
import { baseURL } from "./config"
import toastr from "toastr"
const api = Axios.create({
  baseURL: baseURL,
  timeout: 30000,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "brackets" })
  },
})

api.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + localStorage.access_token
    config.headers["Accept"] = "application/json"
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => {
    // if (response.data.success === false) {
    //   toastr["error"](response.data.message)
    // }
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default api
