import React, { useEffect, useState } from "react"
import "../sass/main.scss"
import { Layout, SEO, HeadSection, ImageSection } from "../components"
import { Row, Col, Container } from "react-bootstrap"
import $ from "jquery"
import axios from "../api/axios"
const CareersPage = () => {
  const [jobs, setJobs] = useState([])
  let department = ""
  useEffect(() => {
    axios.get("frontend/jobs").then((response) => setJobs(response.data.data))
    $(".client").hover(
      function() {
        $(this).addClass("show")
      },
      function() {
        $(this).removeClass("show")
      },
    )
  }, [])
  const headTitle = (): string => {
    return "Careers"
  }
  const headSubTitle = (): string => {
    return `We’re redesigning how companies operate, using the latest technologies and organisational psychology to build a decentralised, swarm-like organisation.`
  }

  const imageDescription = () => {
    return (
      <Col
        xs="3"
        sm="7"
        md="8"
        lg="9"
        className="z-index-2 mb-150 offset-lg-3 offset-md-2 offset-sm-1"
      >
        <div className="st-block st-block--transparent st-block--no-padding">
          <h3>
            We’re a highly unconventional company.
            <span className="font-weight-medium">
              We don't have any managers, hierarchies, politics or bureaucracy.
            </span>
          </h3>
        </div>
      </Col>
    )
  }

  const imageContent = () => {
    return (
      <Row>
        <Col xs="3" sm="8" md="10" lg="12" xl="12">
          <p className="font-weight-medium mb-32">
            Instead, our ethos is to attract and empower smart, kind and curious
            people, and trust them wholeheartedly to take care of our purpose,
            products and profits.
          </p>
          <p className="mb-32">
            If you want to work on something different, and do so in an entirely
            different way, check out our open positions.
          </p>
          <a href="#open-positions" className="learn-more">
            See open positions
          </a>
        </Col>
      </Row>
    )
  }
  return (
    <Layout step={2} darkMode={true} page="careers">
      <SEO
        title="Join the team - Satalia | Enterprise AI"
        description="Satalia is using concepts like decentralisation to redesign how organisations operate. Check out our open positions."
        twitterTitle="Join the team - Satalia | Enterprise AI"
        twitterDescription="Satalia is using concepts like decentralisation to redesign how organisations operate. Check out our open positions."
        openGraphTitle="Join the team - Satalia | Enterprise AI"
        openGraphDescription="Check out our open positions to find out more."
      />
      <Container>
        <section id="careers" className="mb-25">
          <HeadSection title={headTitle()} subtitle={headSubTitle()} />
        </section>
        <section id="career-information" className="mb-285">
          <ImageSection
            description={imageDescription()}
            content={imageContent()}
            image="careers"
            align="end"
            aos="fade-right"
          />
        </section>
        <section id="engagement-process" className="mb-285">
          <Row className="justify-content-end">
            <Col xs="3" sm="8" md="10" lg="9" xl="9">
              <Row>
                <Col xs="3" sm="8" md="10" lg="12" xl="12" className="mb-3">
                  <h2>Benefits</h2>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block">
                    <h3>Freedom</h3>
                    <p>
                      Work where, when and how you want to, with flexible hours
                      and remote work. We just ask that you communicate well,
                      and be accountable for your work.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block">
                    <h3>Impact</h3>
                    <p>
                      Work on cutting edge technologies that have a positive
                      impact on our clients, our society and our environment.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block">
                    <h3>Remuneration</h3>
                    <p>
                      Competitive salary, discretionary bonus, enhanced paid
                      family leave, private healthcare, life assurance, income
                      protection, salary sacrifice pension & more (varies by
                      location).
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block">
                    <h3>Autonomy</h3>
                    <p>
                      Most organisational hierarchies breed politics and stifle
                      innovation. So we don't have them. It's just you, your
                      team and your most meaningful work.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block">
                    <h3>Team</h3>
                    <p>
                      Cooperate with and benefit from our diverse, engaged and
                      caring team. Be heard by and hear from colleagues across
                      all roles and levels of experience.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block">
                    <h3>Development</h3>
                    <p>
                      We’re looking for life-long learners who want to master
                      their craft. In return, we’ll support you with strong
                      career development opportunities, mentoring and training
                      allowances.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section id="open-positions" className="pb-75">
          <Row className="justify-content-center">
            <Col xs="3" sm="8" md="8" lg="8">
              <Row>
                <Col xs="3" sm="4" md="5" lg="4">
                  <h2 className="open-positions__title">Open positions</h2>
                  <p>
                    We’re 140 people, distributed across Europe, and hiring
                    across technology and operational roles.
                  </p>
                </Col>
              </Row>
              <Row>
                {jobs.map((job) => {
                  let render = []
                  if (department !== job.department?.name) {
                    department =
                      job.department && job.department.name
                        ? job.department.name
                        : ""
                    render.push(
                      <Col xs="3" sm="8" md="10" lg="12" key={department}>
                        <h3 className="open-positions__department">
                          {department}
                        </h3>
                      </Col>,
                    )
                  }
                  render.push(
                    <Col xs="3" sm="8" md="10" lg="12" key={job.title}>
                      <a
                        href={job.job_url}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        rel="nofollow noreferrer"
                      >
                        <Row>
                          <Col xs="1" sm="4" md="5" lg="6">
                            <p className="open-positions__item">{job.title}</p>
                          </Col>
                          <Col
                            xs="2"
                            sm="4"
                            md="5"
                            lg="6"
                            className="text-right"
                          >
                            <p className="open-positions__item">
                              {Array.prototype.map
                                .call(job.locations, function(item) {
                                  return item.city
                                })
                                .join(", ")}
                            </p>
                          </Col>
                        </Row>
                      </a>
                    </Col>,
                  )
                  return render
                })}
              </Row>
            </Col>
          </Row>
        </section>
      </Container>
    </Layout>
  )
}

export default CareersPage
